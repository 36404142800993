// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import ListingCollection from '../ListingCollection';
import SkeletonHomeHubRow from 'app/shared/modules/skeleton/SkeletonHomeHubRow';
import { fetchCollectionData } from 'app/shared/flux/actions/HomeHubActions';
import Text from 'app/shared/core/Text';

function Collections({ collections, dispatch }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(fetchCollectionData())
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        dispatch(
          ErrorActions.errorHandler({
            error: err,
            errorLocation: 'HomeHub/Collections#fetchCollectionData',
            errorClass: 'dataError.HomeHub/Collections#fetchCollectionData',
          }),
        );
      });
  }, [dispatch]);

  if (isLoaded && collections?.length === 0) {
    return null;
  } else {
    return (
      <section className="HomeHub-collections">
        <div className="HomeHub-collection-header">
          <Text htmlTag="h2" size="xl">
            <strong>For you</strong>
          </Text>
          <hr />
        </div>
        {isLoaded || collections.length > 0 ? (
          <>
            {collections.map((collection, i) => {
              return <ListingCollection {...collection} key={`${collection.title}-${i}`} />;
            })}
          </>
        ) : (
          <>
            <SkeletonHomeHubRow />
            <SkeletonHomeHubRow />
            <SkeletonHomeHubRow />
          </>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collections: state.homehub.collections,
  };
};

export default connect(mapStateToProps)(Collections);
