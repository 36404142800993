// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

class ListingCardRestrictions extends React.Component {
  static displayName = 'ListingCardRestrictions';

  render() {
    const { restrictions, className } = this.props;
    const { studentHousing, seniorHousing, militaryHousing, incomeRestricted } = restrictions;
    const hasNoRestrictions = !studentHousing && !seniorHousing && !militaryHousing && !incomeRestricted;

    if (hasNoRestrictions) {
      return null;
    }

    const restrictionsArray = [];

    if (incomeRestricted) {
      restrictionsArray.push('Income restricted');
    }

    if (seniorHousing) {
      restrictionsArray.push('Senior housing');
    }

    if (studentHousing) {
      restrictionsArray.push('Student housing');
    }

    if (militaryHousing) {
      restrictionsArray.push('Military housing');
    }

    return <div className={cx('ListingCardRestrictions', className)}>{restrictionsArray.join(', ')}</div>;
  }
}

export default connect()(ListingCardRestrictions);
