// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListingCard from './ListingCard';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Text from 'app/shared/core/Text';

const { HOMEHUB_CONTINUE_WITH } = gaEvents;

class ContinueWith extends Component {
  handleClick = () => {
    this.sendAnalyticsEvent('Click');
  };

  sendAnalyticsEvent = (action) => {
    const event = analyticsEvent(HOMEHUB_CONTINUE_WITH, { action });
    this.props.dispatch(event);
  };

  render() {
    const { lastViewedListing } = this.props;

    if (!lastViewedListing) {
      return null;
    }

    return (
      lastViewedListing && (
        <div className="ContinueWith">
          <div className="ContinueWith-header">
            <Text htmlTag="h2" size="xl">
              <strong>Continue with</strong>
            </Text>
            <hr />
          </div>
          <Text size="sm" className="HomeHub-section-title-container">
            Last viewed
          </Text>
          <ListingCard
            listing={lastViewedListing}
            onClick={this.handleClick}
            triggerLocation="my_hub_page"
            triggerObject="property_card"
          />
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  lastViewedListing: state.homehub.lastViewedListing,
});

export default connect(mapStateToProps)(ContinueWith);
