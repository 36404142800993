// @ts-nocheck
/* eslint-enable */
import { getGlobalLogger } from '@zg-rentals/logger-browser';

const logger = getGlobalLogger('floodlight');

function conversion(type, cat) {
  if (__BROWSER__ && window.gtag) {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: `DC-8027397/${type}/${cat}+standard`,
    });
  }
  logger.debug({ type, cat }, 'doubleclick floodlight conversion');
}

export default {
  trackForRentContact({ trusted, isApartmentBldg } = {}) {
    if (isApartmentBldg) {
      // For Rent - Multi Family Listings Call Contact Submission
      conversion('forre0', 'forre003');
    } else if (trusted) {
      // For Rent - Paid Listings Call Contact Submission
      conversion('forre0', 'forre000');
    } else {
      // For Rent - All Other Listings Contact Submission
      conversion('forre0', 'forre001');
    }
  },

  trackForRentPhoneContact({ trusted, isApartmentBldg } = {}) {
    if (isApartmentBldg) {
      // 7002573 For Rent - Multi Family Listings Call Contact Submission
      conversion('forre0', 'forre004');
    } else if (trusted) {
      // 7043845 For Rent - Paid Listings Call Contact Submission
      conversion('forre0', 'forre006');
    } else {
      // 7043842 For Rent - All Other Listings Call Contact Submission
      conversion('forre0', 'forre005');
    }
  },

  trackListingView({ trusted, isApartmentBldg } = {}) {
    if (isApartmentBldg) {
      // 6212727 For Rent - Multi Family Listings
      conversion('forre0', 'forre002');
    } else if (trusted) {
      // 6098253 For Rent - Paid Listings Page
      conversion('forre0', 'forre0');
    } else {
      // 6085751 For Rent - All Other Listings
      conversion('forre0', 'forre00');
    }
  },

  trackHomepageView() {
    // 6089038 Homepage
    conversion('homep0', 'homep0');
  },

  trackPayPerClick() {
    // 7052815 For Rent - Listing Page Paid Click
    conversion('forre0', 'forre007');
  },
};
