// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Center from 'app/shared/modules/Center';
import Container from 'app/shared/core/Container';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import Filter from 'app/shared/models/Filter';
import Linker from 'app/shared/modules/Linker';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import ListingSquareList from 'app/shared/modules/listing-cards/ListingSquareList';
import Row from 'app/shared/core/Row';
import SkeletonPopularMfBuildings from 'app/shared/modules/skeleton/SkeletonPopularMfBuildings';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

const StyledBoldText = styled(Text)`
  font-weight: 700;
`;
class PopularMfBuildings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: [],
      isLoading: true,
      area: {},
    };
    this.trackClick = this.trackClick.bind(this);
  }

  componentDidMount() {
    const { dispatch, geolocation } = this.props;

    dispatch(UserSearchActions.getRecentSearches()).then((data) => {
      // Addresses HPWEB-3408 where MfPopularBuildings weren't loading
      // due to `areaInfo` not containing any coordinate info.
      const doesAreaInfoExist = data && data.areaInfo;
      const coordProps = ['minLat', 'minLon', 'maxLat', 'maxLon'];
      const containsCoordProps = (currentValue) => {
        return !isNil(data.areaInfo[currentValue]);
      };

      // Prioritize recent search; geolocation as fallback
      const area = doesAreaInfoExist && coordProps.every(containsCoordProps) ? data.areaInfo : geolocation;

      this.fetchMfListings(area);
    });
  }

  fetchMfListings = (area) => {
    const { dispatch } = this.props;

    dispatch(
      ListingEngineActions.fetchMfListingsByArea({
        area,
        filter: new Filter({
          propertyTypes: 'large,medium,garden',
          minPhotos: 1,
          orderBy: 'weekViews',
        }),
      }),
    )
      .then((listings) => {
        this.setState({ listings, isLoading: false, area });
      })
      .catch((error) => {
        dispatch(
          ErrorActions.errorHandler({
            error,
            errorLocation: 'component.popularMfBuildings.componentDidMount',
            errorClass: 'listingEngineActions',
          }),
        );
      });
  };

  trackClick() {
    this.props.dispatch(analyticsEvent(gaEvents.HOME_PAGE_MF_CLICK));
  }

  render() {
    const { listings, area } = this.state;
    const { fullName, resourceId } = area;
    const areaUrl = `/${resourceId}/apartments-for-rent`;

    if (this.state.isLoading) {
      return <SkeletonPopularMfBuildings className="PopularMfBuildings" />;
    } else if (listings.length === 0) {
      return null;
    }

    return (
      <div className="PopularMfBuildings">
        <Row>
          <Container>
            <Center>
              <Row size="lg">
                <Title size="lg" htmlTag="h2">
                  Popular apartment buildings in {fullName}
                </Title>
              </Row>
            </Center>
            <ListingSquareList listings={listings} clickCb={this.trackClick} />
            <Center>
              <Row size="lg">
                <Linker to={areaUrl}>
                  <StyledBoldText responsive={{ smAndUp: 'sm', mdAndUp: 'md' }}>
                    View apartments in {fullName}
                  </StyledBoldText>
                </Linker>
              </Row>
            </Center>
          </Container>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geolocation: state.geolocation.area,
  };
};

export default connect(mapStateToProps)(PopularMfBuildings);
