import type { ReactNode } from 'react';
import React from 'react';

export const getValidChildren = (children: ReactNode): Array<ReactNode> => {
  // .toArray automatically removes invalid React children
  return React.Children.toArray(children);
};

export const addEvent = (
  elem: HTMLElement | null,
  type: string,
  eventHandle: EventListenerOrEventListenerObject,
): void => {
  if (elem === null || typeof elem === 'undefined') {
    return;
  }
  if (elem.addEventListener) {
    elem.addEventListener(type, eventHandle, false);
  } else if ((elem as any).attachEvent) {
    (elem as any).attachEvent(`on${type}`, eventHandle);
  } else {
    (elem as any)[`on${type}`] = eventHandle;
  }
};

export const removeEvent = (
  elem: HTMLElement | null,
  type: string,
  eventHandle: EventListenerOrEventListenerObject,
): void => {
  if (elem === null || typeof elem === 'undefined') {
    return;
  }
  if (elem.removeEventListener) {
    elem.removeEventListener(type, eventHandle, false);
  } else if ((elem as any).detachEvent) {
    (elem as any).detachEvent(`on${type}`, eventHandle);
  } else {
    (elem as any)[`on${type}`] = null;
  }
};

/* From d3-easing */
export function easeCircleOut(t: number): number {
  return Math.sqrt(1 - --t * t);
}
