// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';
import ListingCard from 'app/shared/modules/listing-cards/ListingCard';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';

/**
 * Copied from modules/listing-cards/ListingWrapper and adapted for HomeHub.
 */
class HomeHubListingCard extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    listing: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    const { listing } = this.props;

    this.state = {
      viewed: includes(listing.userItemTypes, 'viewed') || includes(listing.userItemTypes, 'favorite'),
    };
  }

  shouldComponentUpdate(nextProps) {
    const { listing } = this.props;
    const thisPriceDisplay = listing.listingMinMaxPriceBeds.priceDisplay;
    const nextPriceDisplay = nextProps.listing.listingMinMaxPriceBeds.priceDisplay;

    if (listing.userItemTypes !== nextProps.listing.userItemTypes) {
      return true;
    } else if (thisPriceDisplay !== nextPriceDisplay) {
      return true;
    } else {
      return false;
    }
  }

  yieldCbAndTransitionTo = yieldCallback(() => {
    const { dispatch, listing, onClick, filter } = this.props;
    onClick(listing.aliasEncoded);
    dispatch(ListingEngineActions.setPreviewListing(listing));
    dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));
    dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, filter }));
  });

  handleClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    this.yieldCbAndTransitionTo();
  };

  render() {
    const { listing, index } = this.props;
    const { viewed } = this.state;

    return (
      <div onClick={this.handleClick}>
        <ListingCard
          listing={listing}
          listingViewed={viewed}
          lazyLoadImage={index > 1}
          triggerLocation="hp_homepage"
          triggerObject="property_card"
          useThumbPhoto
        />
      </div>
    );
  }
}

export default connect()(HomeHubListingCard);
