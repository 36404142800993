import * as zanalytics from '@zillow/zanalytics';

// API details
// https://gitlab.zgtools.net/zillow/clickstream/static-zillow-analytics#zanalytics.event

const defaultFieldObjectValues = {
  action: 'PropertyImpression',
};

export function impression(fieldObject = {}) {
  if (typeof window === 'undefined') {
    return;
  }

  zanalytics.track('event', {
    ...defaultFieldObjectValues,
    ...fieldObject,
  });
}
