// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import Center from 'app/shared/modules/Center';
import Container from 'app/shared/core/Container';
import Linker from 'app/shared/modules/Linker';
import LinkToggle from 'app/shared/modules/LinkToggle';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';
import states from 'app/shared/utils/states.json';
import Title from 'app/shared/modules/Title';
import 'app/shared/modules/frontpage/PreFooter.scss';

const stateLinks = states.map((state) => {
  return {
    url: `/${state.resourceId}`,
    name: state.name,
  };
});

class PreFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleMore = () => {
    this.setState((state) => {
      return {
        expanded: !state.expanded,
      };
    });
  };

  render() {
    const { isMobile } = this.props;
    const { expanded } = this.state;
    let visibleLinks;

    if (expanded || !isMobile) {
      visibleLinks = stateLinks;
    } else {
      visibleLinks = stateLinks.slice(0, 11);
    }

    return (
      <div className="PreFooter">
        <Container>
          <Section>
            <Center>
              <Row size="lg">
                <Title size="lg" htmlTag="h2">
                  Rental marketplaces
                </Title>
              </Row>
            </Center>
            {visibleLinks.map(({ url, name }, i) => {
              if (url === '/district-of-columbia' || !url) {
                return null;
              }
              return (
                <Row className="PreFooter-state-link" size="sm" key={`state-row-${i}`}>
                  <Center>
                    <h3 className="PreFooter-state-text">
                      <Linker to={`${url}`} key={`link-${name}-${i}`} linkType="accent">
                        {name}
                      </Linker>
                    </h3>
                  </Center>
                </Row>
              );
            })}
            {isMobile && (
              <Row>
                <Center>
                  <LinkToggle onClick={this.toggleMore}>{expanded ? 'Show less' : 'Show more'}</LinkToggle>
                </Center>
              </Row>
            )}
          </Section>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.device.screenWidth === 'sm',
  };
};

export default connect(mapStateToProps)(PreFooter);
