import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';

import AppActions from 'app/shared/flux/actions/AppActions';
import AutocompleteSearchInput from 'app/shared/modules/location/AutocompleteSearchInput';
import Title from 'app/shared/modules/Title';

// This is just a copy of what's in frontpage/HomeHeroCompponent, adjusted to work with HomeHub
class Hero extends React.Component {
  render() {
    // @ts-expect-error TODO: Need to properly type props
    const { hasRecentSearch } = this.props;

    return (
      <div className="HomeHub-hero">
        <Title size="hero" htmlTag="h1">
          {hasRecentSearch ? 'Welcome back.' : 'Find the right apartment for you.'}
        </Title>
        <Title className="HomeHub-subheader" size="lg" htmlTag="h2">
          Search millions of apartments and houses with HotPads.
        </Title>
        <div className="HomeHub-search">
          <AutocompleteSearchInput
            showRecentSearchItem
            large
            defaultItemTypes={['recentSearch', 'geoip']}
            // @ts-expect-error TODO: Need to properly type props
            // eslint-disable-next-line react/jsx-no-bind
            onSelect={() => this.props.dispatch(AppActions.sendEventToApi('homePageMapSearch'))}
            isHomepage
            triggerLocation="hp_homepage"
            triggerObject="hp_home_page_component|search"
          />
        </div>
      </div>
    );
  }
}

// @ts-expect-error TODO: Need to properly type props
const mapStateToProps = (state) => {
  return {
    hasRecentSearch: !isEmpty(state.user.search.recent),
  };
};
export default connect(mapStateToProps)(Hero);
