// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import ExpandSearchButton from './ExpandSearchButton';
import queryUtils from 'app/shared/utils/queryUtils';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Text from 'app/shared/core/Text';
import { adapt_reduxToQuery } from 'app/shared/flux/actions/FilterActions/adapters';

const { HOMEHUB_EXPAND_SEARCH } = gaEvents;

class ExpandMySearch extends Component {
  getSearchRouteForArea = (areaResourceId) => {
    const { filter } = this.props;

    // TODO: The url should already be calculated, and we shouldn't need all these functions
    const path = `/${areaResourceId}/${filter.search.slug}`;
    const queryObj = adapt_reduxToQuery({ filter });
    const url = path + queryUtils.stringify(queryObj);
    return url;
  };

  onAreaSelection = () => {
    this.sendAnalyticsEvent('Click');
  };

  sendAnalyticsEvent = (action) => {
    const { dispatch } = this.props;
    dispatch(analyticsEvent(HOMEHUB_EXPAND_SEARCH, { action }));
  };

  render() {
    const { expandMySearchName, otherPlaces } = this.props;
    if (otherPlaces.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="ExpandMySearch-header">
          <Text htmlTag="h2" size="xl">
            <strong>Expand my search</strong>
          </Text>
          <hr />
        </div>
        <div className="HomeHub-section-title-container">
          <h3 className="HomeHub-section-title">If you like {expandMySearchName}</h3>
        </div>
        <div className="HomeHub-ExpandMySearch">
          {map(otherPlaces, (place) => {
            return (
              <ExpandSearchButton
                key={place.resourceId}
                name={place.name}
                url={this.getSearchRouteForArea(place.resourceId)}
                areaSelection={this.onAreaSelection}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    expandMySearchName: state.homehub.expandMySearchName,
    otherPlaces: state.homehub.expandMySearch,
  };
};

export default connect(mapStateToProps)(ExpandMySearch);
