// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ContinueWith from '../ContinueWith';
import FairHousing from '../FairHousing';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import ExpandMySearch from '../ExpandMySearch';
import SkeletonHomeHubAside from 'app/shared/modules/skeleton/SkeletonHomeHubAside';
import { expandSearch, lastViewed } from 'app/shared/flux/actions/HomeHubActions';

function Aside({ isMobile, recentArea, userLocation, dispatch }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    Promise.all([dispatch(lastViewed()), dispatch(expandSearch({ recentArea, userLocation }))])
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLoaded(true);
        dispatch(
          ErrorActions.errorHandler({
            error: err,
            errorLocation: 'HomeHub/Aside#lastViewedExpandSearch',
            errorClass: 'dataError.HomeHub/Aside#lastViewedExpandSearch',
          }),
        );
      });
  }, [dispatch, recentArea, userLocation]);

  return (
    <>
      <section className="HomeHub-aside">
        {isLoaded ? (
          <>
            <ContinueWith />
            <ExpandMySearch />
            <FairHousing />
          </>
        ) : (
          <SkeletonHomeHubAside isMobile={isMobile} />
        )}
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.app.device.isMobile,
    recentArea: state.user.search.recent.areaInfo,
    userLocation: state.homehub.userLocation,
  };
};

export default connect(mapStateToProps)(Aside);
