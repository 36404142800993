interface TrackParams {
  trusted: boolean;
  isApartmentBldg: boolean;
}

const bing = {
  snippet: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"4017789"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.className='optanon-category-4',n.type='text/plain',n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
  trackEvent({ category = '', action = '', label = '', value = 1 }) {
    window.uetq = window.uetq || [];
    window.uetq.push({ ec: category, ea: action, el: label, ev: value });
    return false;
  },
  trackForRentContact({ trusted, isApartmentBldg }: TrackParams = {} as TrackParams) {
    if (trusted && isApartmentBldg) {
      bing.trackEvent({
        action: 'Multi-family email submit',
        category: 'Submit',
        label: 'Email',
      });
    }
  },
  trackForRentPhoneContact({ trusted, isApartmentBldg }: TrackParams = {} as TrackParams) {
    if (trusted && isApartmentBldg) {
      bing.trackEvent({
        action: 'Multi-family call submit',
        category: 'Submit',
        label: 'Call',
      });
    }
  },
  trackPayPerClick({ trusted, isApartmentBldg }: TrackParams = {} as TrackParams) {
    if (trusted && isApartmentBldg) {
      bing.trackEvent({
        action: 'Multi-family paid click',
        category: 'Link Click',
        label: 'Paid Click',
      });
    }
  },
};

export default bing;
