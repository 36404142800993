// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconArrowDown from 'images/icons/arrow-down.svg';
import cx from 'classnames';
import Linker from 'app/shared/modules/Linker';
import './style.scss';

class LinkToggle extends Component {
  static propTypes = {
    linkType: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    linkType: 'default',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleToggle = () => {
    const { onClick } = this.props;
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    if (onClick) {
      onClick();
    }
  };

  render() {
    const { linkType, className } = this.props;
    const classes = {
      'LinkToggle-arrow': true,
      'LinkToggle-open': this.state.isOpen,
    };
    return (
      <div className={cx('LinkToggle', className)}>
        <Linker className="LinkToggle-linker" onClick={this.handleToggle} linkType={linkType} useButton>
          <img src={IconArrowDown} className={cx(classes)} alt="" />
          {this.props.children}
        </Linker>
      </div>
    );
  }
}

export default LinkToggle;
