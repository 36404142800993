import type { CSSProperties } from 'react';
import React from 'react';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';

interface PagingDotsProps {
  slideCount: number;
  slidesToScroll: number;
  currentSlide: number;
  // eslint-disable-next-line no-unused-vars
  goToSlide: (index: number) => void;
  ariaLabel: string;
}

const PagingDots: React.FC<PagingDotsProps> = ({
  slideCount = 0,
  slidesToScroll = 0,
  currentSlide = 0,
  goToSlide = () => {},
  ariaLabel = '',
}) => {
  const getIndexes = (count: number, increment: number): Array<number> => {
    const indexes: Array<number> = [];
    increment = Math.max(increment, 1);

    for (let index = 0; index < count; index += increment) {
      indexes.push(index);
    }
    return indexes;
  };

  const getButtonStyle = (active: boolean): CSSProperties => {
    return {
      background: active ? '#007a70' : '#d8d8d8',
    };
  };

  const indexes = getIndexes(slideCount, slidesToScroll);

  return (
    <div className="Carousel-paging" role="navigation" aria-label={ariaLabel}>
      <ul className="Carousel-paging-ul">
        {indexes.map((index) => (
          <li key={index} className="Carousel-paging-li">
            <button
              className="Carousel-paging-button"
              aria-pressed={currentSlide === index}
              onClick={() => goToSlide(index)}
            >
              <div className="Carousel-paging-dot" style={getButtonStyle(currentSlide === index)} />
              <VisuallyHidden>Group {index}</VisuallyHidden>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PagingDots;
