// @ts-nocheck
/* eslint-enable */
import React from 'react';

import AnimatedFooter from './AnimatedFooter';
import Aside from './Aside';
import Collections from './Collections';
import Container from 'app/shared/core/Container';
import FooterNav from 'app/shared/modules/navigation/footer/FooterNav';
import Hero from './Hero';
import TopCities from 'app/shared/modules/frontpage/TopCities';
import './style.scss';

/* eslint-disable react/no-multi-comp */

const renderSnowflakes = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Month integer is offset by -1
  const currentDay = currentDate.getDate();

  if (currentMonth === 12 && currentDay >= 20 && currentDay <= 26) {
    return (
      <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
      </div>
    );
  }

  return null;
};

function HomeHub() {
  return (
    <React.Fragment>
      {renderSnowflakes()}
      <Container size="lg" className="HomeHub">
        <Hero />
        <div className="HomeHub-sections">
          <Collections />
          <Aside />
        </div>
      </Container>
      <AnimatedFooter />
      <TopCities />
      <FooterNav crossbrand />
    </React.Fragment>
  );
}

export default HomeHub;
