import React from 'react';
import loadable from '@loadable/component';
import type { HomeHeroComponentProps } from 'app/shared/modules/frontpage/HomeHeroComponent';

const HomeHero = loadable(
  () => import(/* webpackChunkName: "HomeHero.loadable" */ 'app/shared/modules/frontpage/HomeHeroComponent'),
);

const LoadableHomeHero = (props: HomeHeroComponentProps) => {
  return <HomeHero {...props} />;
};

export default LoadableHomeHero;
