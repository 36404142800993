// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { yieldCallback } from '@zillow/yield-callback';
import 'app/shared/modules/frontpage/TopCities.scss';
import styled from 'styled-components';
import { inset, padding, stacked } from 'app/shared/styles/_spacing';
import { textOverflow } from 'app/shared/styles/_mixins';
import Linker from 'app/shared/modules/Linker';
import Container from 'app/shared/core/Container';
import Section from 'app/shared/modules/Section';
import Center from 'app/shared/modules/Center';
import Title from 'app/shared/modules/Title';
import { connect } from 'react-redux';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';

const StyledTitle = styled(Title)`
  ${padding.top._3x};
  ${stacked._3x};
`;
const StyledInsetListItem = styled.li`
  ${inset._xs};
  ${textOverflow};
`;
const TOP_CITIES = [
  {
    title: 'San Francisco Apartments',
    url: '/san-francisco-ca/apartments-for-rent',
  },
  {
    title: 'Chicago Apartments',
    url: '/chicago-il/apartments-for-rent',
  },
  {
    title: 'Los Angeles Apartments',
    url: '/los-angeles-ca/apartments-for-rent',
  },
  {
    title: 'Boston Apartments',
    url: '/boston-ma/apartments-for-rent',
  },
  {
    title: 'Seattle Apartments',
    url: '/seattle-wa/apartments-for-rent',
  },
  {
    title: 'Houston Apartments',
    url: '/houston-tx/apartments-for-rent',
  },
  {
    title: 'New York Apartments',
    url: '/new-york-ny/apartments-for-rent',
  },
  {
    title: 'Austin Apartments',
    url: '/austin-tx/apartments-for-rent',
  },
  {
    title: 'Atlanta Apartments',
    url: '/atlanta-ga/apartments-for-rent',
  },
  {
    title: 'San Diego Apartments',
    url: '/san-diego-ca/apartments-for-rent',
  },
  {
    title: 'Las Vegas Apartments',
    url: '/las-vegas-nv/apartments-for-rent',
  },
  {
    title: 'Dallas Apartments',
    url: '/dallas-tx/apartments-for-rent',
  },
  {
    title: 'Denver Apartments',
    url: '/denver-co/apartments-for-rent',
  },
  {
    title: 'Nashville Apartments',
    url: '/nashville-tn/apartments-for-rent',
  },
  {
    title: 'San Antonio Apartments',
    url: '/san-antonio-tx/apartments-for-rent',
  },
  {
    title: 'Philadelphia Apartments',
    url: '/philadelphia-pa/apartments-for-rent',
  },
  {
    title: 'Miami Apartments',
    url: '/miami-fl/apartments-for-rent',
  },
  {
    title: 'Washington D.C. Apartments',
    url: '/washington-dc/apartments-for-rent',
  },
];
class TopCities extends React.Component {
  yieldTrackAndTransitionTo = yieldCallback((path) => {
    this.props.dispatch(analyticsEvent(gaEvents.AREAPAGE_TOP_CITIES_CLICK));
    window.router.transitionTo(path);
  });
  handleTopCityClick = (e, path) => {
    // Prevent the browser from going through with its default <a> behavior
    e.preventDefault();

    this.yieldTrackAndTransitionTo(path);
  };

  render() {
    return (
      <Container className="TopCities">
        <Section>
          <Center>
            <StyledTitle size="lg" htmlTag="h2">
              Top cities
            </StyledTitle>
            <ul className="TopCities-group">
              {TOP_CITIES.map((city) => (
                <StyledInsetListItem className="TopCities-city-item TopCities-city-text" key={city.title}>
                  <Linker onClick={(e) => this.handleTopCityClick(e, city.url)} linkType="accent" to={city.url}>
                    {city.title}
                  </Linker>
                </StyledInsetListItem>
              ))}
            </ul>
          </Center>
        </Section>
      </Container>
    );
  }
}

export default connect()(TopCities);
