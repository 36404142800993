// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Linker from 'app/shared/modules/Linker';
import IconFairHousing from 'images/icons/income-restricted-new.svg';

class FairHousing extends Component {
  render() {
    const { isNewYorkGeoIp, isNewYorkRecentSearch } = this.props;
    if (!isNewYorkGeoIp && !isNewYorkRecentSearch) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="FairHousing-header">
          <Text htmlTag="h2" size="xl">
            <strong>New York + Fair Housing</strong>
          </Text>
          <hr />
        </div>
        <Row>
          <div className="FairHousing-body">
            <div className="FairHousing-icon-container">
              <img className="FairHousing-icon" src={IconFairHousing} />
            </div>
            <div className="FairHousing-info">
              <Linker
                linkType="underline"
                target="_blank"
                to="https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf "
              >
                <Text size="md">Learn about unlawful housing discrimination.</Text>
              </Linker>
            </div>
          </div>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isNewYorkGeoIp: !isEmpty(state.geolocation.area) && state.geolocation.area.state === 'NY',
    isNewYorkRecentSearch:
      !isEmpty(state.user.search.recent.areaInfo) && state.user.search.recent.areaInfo.state === 'NY',
  };
};
export default connect(mapStateToProps)(FairHousing);
