// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Center from 'app/shared/modules/Center';
import Container from 'app/shared/core/Container';
import Row from 'app/shared/core/Row';
import SkeletonImage from 'app/shared/modules/skeleton/SkeletonImage';
import SkeletonTitle from 'app/shared/modules/skeleton/SkeletonTitle';
import 'app/shared/modules/skeleton/SkeletonPopularMfBuildings/style.scss';

class SkeletonPopularMfBuildings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <Container>
          <Center>
            <Row size="lg">
              <SkeletonTitle />
            </Row>
          </Center>
          <div className="SkeletonListingSquareList">
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
            <div className="SkeletonListingSquareList-item">
              <SkeletonImage />
            </div>
          </div>
          <Center>
            <Row size="lg">
              <SkeletonTitle />
            </Row>
          </Center>
        </Container>
      </div>
    );
  }
}

export default SkeletonPopularMfBuildings;
