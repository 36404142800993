// @ts-nocheck
/* eslint-enable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';
import { DeferredRender, RenderMode } from '@zillow/react-deferred-hydration';

// Actions
import UserItemActions from 'app/shared/flux/actions/UserItemActions';

// Components
import FavoriteButton from 'app/shared/modules/FavoriteButton';
import LinkController from 'app/shared/modules/LinkController';
import StaticStarRating from 'app/shared/modules/StaticStarRating';
import Text from 'app/shared/core/Text';

// Misc / Utils
import * as S from './styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import formatter from 'app/shared/utils/formatter';

import IconBike from 'images/icons/bike-white.svg';
import IconBus from 'images/icons/bus-white.svg';
import IconCar from 'images/icons/car-white.svg';
import IconFeet from 'images/icons/feet-white.svg';

import IconBuilding from 'images/icons/building.svg';
import IconPromo from 'images/icons/promo.svg';
import IconVerified from 'images/verified.svg';
import Icon3DGlasses from 'images/icons/3d-glasses.svg';
import { listingUtils_checkFavorite, listingUtils_getEffectivePropertyType } from 'app/shared/utils/listingUtils';
import { TrackSaveHomeClick, TrackUnSaveHomeClick } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const StyledBoldText = styled(Text)`
  font-weight: 700;
`;
class ListingCard extends Component {
  constructor(props) {
    super(props);
  }

  getKeyword() {
    const { listing } = this.props;
    let keyword = formatter.string.upperFirstLowerRest(listing.searchKeyword);
    const propType = listingUtils_getEffectivePropertyType(listing.propertyType);

    if (listing.listingType === 'room') {
      keyword = formatter.string.upperFirstLowerRest(`${listing.listingType} in ${propType}`);
    }

    return keyword;
  }

  yieldFavoriteClick = yieldCallback(() => {
    const { listing, dispatch, triggerLocation, triggerObject } = this.props;
    const isFavorited = listingUtils_checkFavorite(listing);
    if (!isFavorited) {
      dispatch(
        analyticsEvent(gaEvents.HDP_FAVORITE_ATTEMPT, {
          newLaneEvent: TrackSaveHomeClick({ listing, triggerLocation, triggerObject }),
        }),
      );
    } else {
      dispatch(analyticsEvent({ newLaneEvent: TrackUnSaveHomeClick({ listing, triggerLocation, triggerObject }) }));
    }

    dispatch(UserItemActions.toggleUserItem('favorite', listing));
  });

  handleFavoriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.yieldFavoriteClick();
  };

  render() {
    const { listing, listingViewed, commuteMode, commuteTime, showTransitTime, useThumbPhoto } = this.props;

    const {
      active,
      address,
      averageStarLevel,
      building,
      displayName,
      hasSpecialOffers,
      incomeRestricted,
      listingMinMaxPriceBeds,
      militaryHousing,
      neighborhood,
      previewPhoto,
      recencyTime,
      seniorHousing,
      studentHousing,
      trusted,
      unitCount,
      has3dTour,
      broker,
      thumbPhoto,
    } = listing;

    let commuteIcon;
    const listingPath = listing.uriV2;
    const neighborhoodLength = neighborhood ? neighborhood.length : 0;
    const shouldDisplayBrokerInfo = Boolean(!building && broker?.isBrokerExclusiveListing && broker?.companyName);
    const hideAddress = address.hideStreet;

    switch (commuteMode) {
      case 'DRIVING':
        commuteIcon = IconCar;
        break;
      case 'TRANSIT':
        commuteIcon = IconBus;
        break;
      case 'WALKING':
        commuteIcon = IconFeet;
        break;
      case 'CYCLING':
        commuteIcon = IconBike;
        break;
      default:
        commuteIcon = IconBike;
    }

    return (
      <>
        <S.ListingCard
          listingViewed={listingViewed}
          isBrokerListing={shouldDisplayBrokerInfo}
          aria-label={`See more details about ${hideAddress ? 'this listing' : displayName}`}
        >
          <DeferredRender
            mode={this.props.lazyLoadImage ? RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE : null}
            visibilityOptions={{ rootMargin: '200px' }}
          >
            <S.ListingCardContainer building={building}>
              <S.ListingCardPhotoContainer>
                {building && (
                  <S.ListingCardBuildingOverlay>
                    <Fragment>
                      <StyledBoldText size="lg">{unitCount}</StyledBoldText>
                    </Fragment>
                    <Fragment>
                      <StyledBoldText>units</StyledBoldText>
                    </Fragment>
                  </S.ListingCardBuildingOverlay>
                )}
                <S.ImageLoader
                  altText={`${hideAddress ? 'Listing' : displayName} Photo 1`}
                  titleText={`${hideAddress ? 'Listing' : displayName} Photo 1`}
                  image={useThumbPhoto ? thumbPhoto?.url : previewPhoto?.url}
                  fetchPriority={this.props.lazyLoadImage ? 'low' : 'high'}
                />
                {!building && (
                  <S.ListingCardFavorite>
                    <FavoriteButton
                      listing={listing}
                      alreadyFavorited={listingUtils_checkFavorite(listing)}
                      onClick={this.handleFavoriteClick}
                    />
                  </S.ListingCardFavorite>
                )}
                {showTransitTime && (
                  <S.ListingCardTransitTime>
                    <S.ListingCardTransitIcon src={commuteIcon} width="12px" height="12px" alt="" />
                    {commuteTime} min
                  </S.ListingCardTransitTime>
                )}
              </S.ListingCardPhotoContainer>
              <S.ListingCardContentContainer>
                {trusted && (
                  <S.ListingCardVerified>
                    <S.ListingCardIconVerifiedImg
                      src={IconVerified}
                      height="16px"
                      width="16px"
                      alt="This is a verified listing"
                    />
                  </S.ListingCardVerified>
                )}
                {building && (
                  <S.ListingCardBuildingIcon>
                    <img src={IconBuilding} width="20px" height="20px" alt="This listing has multiple units" />
                  </S.ListingCardBuildingIcon>
                )}
                {has3dTour && (
                  <S.ListingCard3dTourGlasses>
                    <img src={Icon3DGlasses} height="18px" width="18px" alt="This listing has a 3D tour available" />
                  </S.ListingCard3dTourGlasses>
                )}
                <S.ListingCardPrice>{listingMinMaxPriceBeds.priceDisplay}</S.ListingCardPrice>
                {hasSpecialOffers && (
                  <S.ListingCardPromo>
                    <S.ListingCardPromoIcon
                      src={IconPromo}
                      height="10px"
                      width="10px"
                      alt="This listing has a special promotion - contact for details"
                    />
                    <S.ListingCardPromoText>Offer</S.ListingCardPromoText>
                  </S.ListingCardPromo>
                )}
                <S.ListingCardBedDisplay>
                  {listingMinMaxPriceBeds.bedDisplay}
                  {listingMinMaxPriceBeds.bathDisplay && <span>, {listingMinMaxPriceBeds.bathDisplay}</span>}
                </S.ListingCardBedDisplay>
                <LinkController onClick={(e) => e.preventDefault()} to={listingPath}>
                  <S.ListingCardName>{hideAddress ? '(Undisclosed Address)' : displayName}</S.ListingCardName>
                </LinkController>
                {neighborhoodLength > 0 ? (
                  <S.ListingCardText>
                    {neighborhood}, {address.city}, {address.state}
                  </S.ListingCardText>
                ) : (
                  <S.ListingCardText>
                    {address.city}, {address.state} {address.zip}
                  </S.ListingCardText>
                )}
                <S.ListingCardExtras>
                  {!active && <S.ListingCardExpiredTag>NO LONGER AVAILABLE</S.ListingCardExpiredTag>}
                  {Boolean(averageStarLevel) && (
                    <S.ListingCardRating>
                      <StaticStarRating filledStars={averageStarLevel} totalStars={5} size="sm" />
                    </S.ListingCardRating>
                  )}
                  {active && recencyTime.daysDiff < 5 && (
                    <S.ListingCardRecency>New! {recencyTime.displayDiff} ago</S.ListingCardRecency>
                  )}
                  <S.ListingCardRestrictions
                    restrictions={{
                      studentHousing,
                      incomeRestricted,
                      seniorHousing,
                      militaryHousing,
                    }}
                  />
                  {active && !averageStarLevel && (
                    <S.ListingCardKeyword listingType={listing.listingType}>{this.getKeyword()}</S.ListingCardKeyword>
                  )}
                </S.ListingCardExtras>
              </S.ListingCardContentContainer>
            </S.ListingCardContainer>
          </DeferredRender>
        </S.ListingCard>
        {shouldDisplayBrokerInfo && (
          <S.NYDOSText>
            {broker.companyName}
            {broker.companyPhoneNumber && (
              <>
                {', '}
                <S.NYDOSPhoneNumber as="span">{broker.companyPhoneNumber}</S.NYDOSPhoneNumber>
              </>
            )}
          </S.NYDOSText>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientLoaded: state.app.clientLoaded,
  };
};

export default connect(mapStateToProps)(ListingCard);
