// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';

// Components
import ImageLoaderBase from 'app/shared/modules/photo-gallery/ImageLoader';
import ListingCardRestrictionsBase from 'app/shared/modules/listing-cards/ListingCardRestrictions';
import LazyLoaderBase from 'app/shared/modules/LazyLoader';

export const ListingCard = styled.div`
  text-align: left;
  background-color: ${colors['$hpx-white']};
  width: 100%;
  ${(props) =>
    props.isBrokerListing &&
    `
        margin-bottom: 8px;
    `}

  ${(props) =>
    props.listingViewed &&
    `
        background-color: ${colors['$hpx-grey-100']};
    `}

    &:hover {
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const ListingCardContainer = styled.div`
  height: 104px;
  color: ${colors['$hpx-blue-600']};
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;

  ${(props) =>
    props.building &&
    `
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 10px 0 -7px ${colors['$hpx-white']}, 0 10px 1px -6px rgba(0, 0, 0, 0.2), 0 20px 0 -14px ${colors['$hpx-white']}, 0 20px 1px -13px rgba(0, 0, 0, 0.2);
    `}
`;

export const ListingCardVerified = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
`;

export const ListingCardIconVerifiedImg = styled.img`
  vertical-align: middle;
  margin-left: 4px;
`;

export const ListingCardPhotoContainer = styled.div`
  width: 100px;
  height: 100%;
  position: absolute;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  background-color: ${colors['$hpx-grey-400']};
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
`;

export const ImageLoader = styled(ImageLoaderBase)`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  font-size: 12px;
`;

export const ListingCardContentContainer = styled.div`
  display: block;
  margin-left: 100px;
  padding: 12px;
  padding-right: 8px;
`;

export const ListingCardBedDisplay = styled.div`
  font-size: 12px;
  position: static;
  margin-bottom: 4px;
`;

export const ListingCardName = styled.h3`
  font-size: 12px;
  color: ${colors['$hpx-grey-600']};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  font-weight: normal;
  text-decoration: none;
`;

export const ListingCardText = styled.div`
  font-size: 12px;
  color: ${colors['$hpx-grey-600']};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
`;

export const ListingCardExtras = styled.div`
  ${font.tiny};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ListingCardKeyword = styled.div`
  color: ${colors['$hpx-grey-600']};
  display: inline;

  ${(props) =>
    props.listingType &&
    props.listingType === 'room' &&
    `
        color: ${colors['$hpx-teal-700']};
    `}
`;

export const ListingCardExpiredTag = styled.div`
  color: ${colors['$hpx-grey-600']};
  display: inline;
`;

export const ListingCardRecency = styled.div`
  display: inline-block;
  padding-right: 4px;
  color: ${colors['$hpx-red-500']};
  fill: ${colors['$hpx-red-500']};
`;

export const ListingCardRating = styled.div`
  display: inline-block;
  padding-right: 4px;
`;

export const ListingCardRestrictions = styled(ListingCardRestrictionsBase)`
  font-size: 12px;
  color: ${colors['$hpx-teal-700']};
  display: inline-block;
  padding-right: 4px;
`;

export const ListingCardFavorite = styled.div`
  color: ${colors['$hpx-white']};
  position: absolute;
  top: -8px;
  right: -8px;
`;

export const ListingCardPrice = styled.div`
  max-width: 64px;
  font-weight: bold;
  display: inline-block;
`;

export const ListingCardPromo = styled.div`
  width: 58px;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: ${colors['$hpx-grey-400']};
  display: inline-block;
  line-height: 1;
  margin-left: 2px;
  vertical-align: bottom;
  text-align: center;
`;

export const ListingCardPromoIcon = styled.img`
  vertical-align: middle;
`;

export const ListingCardPromoText = styled.span`
  ${font.tiny};
  padding-left: 4px;
`;

export const ListingCard3dTourGlasses = styled.div`
  position: absolute;
  right: 28px;
  margin-left: 4px;
`;

export const ListingCardBuildingIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${colors['$hpx-blue-600']};
  display: inline-block;
  padding: 8px 8px 5px 8px;
  background-color: ${colors['$hpx-grey-100']};
  border-radius: 3px;
  margin-bottom: 8px;
`;

export const ListingCardBuildingOverlay = styled.div`
  position: absolute;
  border-radius: 3px;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${zindex['$z-index-step']};
  text-align: center;
  color: ${colors['$hpx-white']};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LazyLoader = styled(LazyLoaderBase)`
  border-radius: 3px 0 0 3px;
  background-color: ${colors['$hpx-grey-100']};
`;

export const ListingCardTransitIcon = styled.img`
  margin-right: 8px;
`;

export const ListingCardTransitTime = styled.div`
  align-items: center;
  background-color: ${colors['$hpx-teal-500']};
  border-radius: 4px;
  bottom: 4px;
  color: ${colors['$hpx-white']};
  display: flex;
  font-size: 10px;
  height: 18px;
  justify-content: center;
  left: 4px;
  line-height: 18px;
  position: absolute;
  width: 61px;
`;

export const NYDOSText = styled.p`
  ${font.tiny};
  color: ${colors['$hpx-grey-600']};
  letter-spacing: 0.5px;
`;

export const NYDOSPhoneNumber = styled(NYDOSText)`
  white-space: nowrap;
`;
