// @ts-nocheck
/* eslint-enable */
import { FB_DEFAULT_QUERY, FB_URL } from './constants';
import queryUtils from 'app/shared/utils/queryUtils';

// pageType
const FOR_RENT = 'ForRent HDP';
const PAID = 'ForRent BDP';

// contactType
const EMAIL = 'email';
const PHONE = 'phone';
const PAID_CLICK = 'paid click';

function appendImg(query) {
  if (!__BROWSER__) {
    return;
  }

  const src = FB_URL + queryUtils.stringify(query);
  const img = document.createElement('img');
  img.setAttribute('height', 1);
  img.setAttribute('width', 1);
  img.setAttribute('border', 0);
  img.setAttribute('alt', '');
  img.setAttribute('style', 'display:none');
  img.setAttribute('referrerpolicy', 'no-referrer');
  img.setAttribute('src', src);
  document.body.appendChild(img);
}

function track({ event, pageType, contactType, lotId, aliasEncoded, city, state, zip }) {
  const query = {
    ...FB_DEFAULT_QUERY,
    ev: event,
    'cd[content_type]': 'product',
  };
  if (aliasEncoded) {
    //temporarily send in alias for the 2019 ad campaign instead of lotId
    query['cd[content_ids]'] = aliasEncoded;
  }
  if (contactType) {
    query['cd[ContactType]'] = contactType;
  }
  if (pageType) {
    query['cd[PageType]'] = pageType;
  }
  if (state) {
    query['cd[State]'] = state;
  }
  if (city) {
    query['cd[City]'] = city;
  }
  if (zip) {
    query['cd[ZipCode]'] = zip;
  }

  return query;
}

function trackPurchase(props) {
  const event = 'Purchase';
  const query = track({ ...props, event });
  query['cd[value]'] = 0.0;
  query['cd[currency]'] = 'USD';

  return query;
}

export default {
  trackForRentContact(listing = {}) {
    const { trusted, maloneLotIdEncoded, address, aliasEncoded } = listing;
    const pageType = trusted ? PAID : FOR_RENT;

    const query = trackPurchase({
      pageType,
      contactType: EMAIL,
      lotId: maloneLotIdEncoded,
      aliasEncoded,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });

    appendImg(query);
  },

  trackForRentPhoneContact(partialListing = {}) {
    const { trusted, maloneLotIdEncoded, address, aliasEncoded } = partialListing;
    const pageType = trusted ? PAID : FOR_RENT;

    const query = trackPurchase({
      pageType,
      contactType: PHONE,
      lotId: maloneLotIdEncoded,
      aliasEncoded,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });

    appendImg(query);
  },

  trackPayPerClick(listing = {}) {
    const { maloneLotIdEncoded, address, aliasEncoded } = listing;

    const query = trackPurchase({
      pageType: FOR_RENT,
      contactType: PAID_CLICK,
      lotId: maloneLotIdEncoded,
      aliasEncoded,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });

    appendImg(query);
  },

  trackListingView(listing = {}) {
    const { trusted, maloneLotIdEncoded, address, aliasEncoded } = listing;
    const pageType = trusted ? FOR_RENT : PAID;

    const query = track({
      event: 'ViewContent',
      pageType,
      lotId: maloneLotIdEncoded,
      aliasEncoded,
      city: address.city,
      state: address.state,
      zip: address.zip,
    });

    appendImg(query);
  },

  trackHomepageView() {
    const query = track({
      event: 'ViewContent',
      pageType: 'Homepage',
    });

    appendImg(query);
  },
};
