import type { FC, ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';

interface ListItemProps {
  index: number;
  // eslint-disable-next-line no-unused-vars
  reportWidth: (info: { index: number; width: number }) => void;
  hidden?: boolean;
  children?: ReactNode;
}

const ListItem: FC<ListItemProps> = ({ index, reportWidth, hidden, children }) => {
  const ref = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      reportWidth({
        index,
        width: ref.current.getBoundingClientRect().width,
      });
    }
  }, [index, reportWidth]);

  return (
    <li className={`Carousel-li${hidden ? ' Carousel-li-hidden' : ''}`} ref={ref}>
      {children}
    </li>
  );
};

export default ListItem;
