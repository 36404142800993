// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import Animation from 'app/shared/modules/Animation';
import animationData from 'app/shared/modules/frontpage/HomeHeroAnimation.json';
import BackgroundSrc from 'images/homeHeroLargeBackground.svg';
import Button from 'app/shared/core/Button';

export default class AnimatedFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: true,
    };
  }

  handlePausePlay = () => {
    const { isPlaying } = this.state;
    this.setState({ isPlaying: !isPlaying });
  };

  render() {
    const { isPlaying } = this.state;
    return (
      <div className="HomeHub-AnimatedFooter" aria-hidden="true">
        <div className="HomeHub-AnimatedFooter-contents">
          <Animation
            className="HomeHub-AnimatedFooter-animation"
            animationData={animationData}
            shouldLoop
            shouldAutoplay
            isPlaying={isPlaying}
          />
          <img src={BackgroundSrc} className="HomeHub-AnimatedFooter-background" alt="" />
        </div>
        <div className="HomeHub-AnimatedFooter-pause-play-button">
          <Button onClick={this.handlePausePlay} btnType="default" size="sm">
            {isPlaying ? 'Pause' : 'Play'}
          </Button>
        </div>
      </div>
    );
  }
}
